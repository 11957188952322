import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { API_GET_POSTER_COMPANIES, postApiPoster } from '../../../../utils';
import EditButton from "../../../../assets/images/edit-icon.png";
import RightArrow from "../../../../assets/images/right-arrow.png";

export default function CompaniesPage() {
const navigate = useNavigate();
const location = useLocation();

const [companyList, setCompanyList] = useState([]);
const [loading, setLoading] = useState(true);

const userToken = useSelector(state => state.UserAuth.userData.payload.token);
const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

useEffect(() => {
const requestDataFromServer = () => {
	const payload = { userId };
	postApiPoster(API_GET_POSTER_COMPANIES, payload, userToken, handleSuccess, handleError);
};

requestDataFromServer();
}, [userId, userToken]);

const handleSuccess = res => {
setLoading(false);
if (res.status === 200) {
	setCompanyList(res.data.result);
}
};

const handleError = () => {
setLoading(false);
toast.error('Oops! Something went wrong!', {
	position: 'top-right',
	autoClose: 1000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
});
};

const goToAddOrEditCompany = () => {
const basePath = location.pathname.includes("/companies") ? '../' : './';
navigate(basePath + 'addCompany');
};

const handleCompanyClick = (company, isEdit = false) => {
const basePath = location.pathname.includes("companies") ? '..' : '.';
const path = isEdit ? 'editCompany/' : 'company/';
navigate(`${basePath}/${path}${company._id}`, { state: company });
};

return (
<React.Fragment>
	{loading ? (
	<div className="d-flex justify-content-center align-items-center LoaderCenter">
		<div className="block">
		<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true" />
		<h6 className="LoaderText">Processing...</h6>
		</div>
	</div>
	) : (
	<div className="container">
		<div className="row dashboard-cnt-row d-flex align-items-center justify-content-center">
		<div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
			<div className="row">
			<div className="col-lg-12 recent-page-heading-jp mb-4">
				<br />
				<div className="perspectv-header" style={{ background: '#ffdb59' }}>Your Companies</div>
			</div>
			</div>
			<div className="row mt-5 pb-5">
				{companyList.length > 0 ? (
					companyList.map(company => (
					<div key={company._id} className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-2">
						<div className="current-job-col p-2">
							<div className="col-12">
								<div className="">
									<div className="d-flex justify-content-between">
										<h5 onClick={() => handleCompanyClick(company)}>{company.name}</h5>
										<div>
											<img src={EditButton} alt="Edit" style={{ height: "20px" }} onClick={() => handleCompanyClick(company, true)} />
										</div>
									</div>
									<div onClick={() => handleCompanyClick(company)}>
										<p className="capitalize m-0">Location <span className="float-end">{company.location || "Not Set"}</span></p>
										<p className="capitalize mt-auto m-0">Created On <span className="float-end">{company.createdAt.substring(0, 16) || ""}</span></p>	
									</div>
								</div>
							</div>
						</div>
					</div>
					))
				) : (
					<div>
						<p>You currently have no companies added to your profile.</p>
						<p>To add a company, click on the add button below.</p>
					</div>
				)}
			</div>
			<div className="row">
				<div className="col-lg-12 mt-5 text-center mb-5">
					<div className="perspectv-button" onClick={goToAddOrEditCompany}>
						<i className="fa fa-plus" style={{ fontSize: '1.5rem' }} />
					</div>
				</div>
			</div>
		</div>
		</div>
	</div>
	)}
</React.Fragment>
);
}