import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { generateHexUUID } from "../../../../utils";
import { getJobById as fetchjobposterApi } from "../../../../api/jobs-data";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";

const SoftSkillsComp = ({_jobId, _onResp }) => {
const [isAddButton, setAddButton] = useState(false);
const [currentAddedSkill, setCurrentAddedSkill] = useState([]);
const [softSkillSelectedForInputUI, setSoftSkillSelectedForInputUI] = useState("");

const primarySliderObj = {
    0: { exp: "0-2 years", explevel: "entry" },
    1: { exp: "2-5 years", explevel: "senior" },
    2: { exp: "5-10 years", explevel: "expert" }
};

const demoSoftSkills = [
    // { name: "Communication", level: "Expert" },
    // { name: "Problem-Solving", level: "Expert" },
    // { name: "Teamwork & Collaboration", level: "Advanced" },
    // { name: "Adaptability & Flexibility", level: "Advanced" },
    // { name: "Time Management", level: "Advanced" },
    // { name: "Leadership", level: "Intermediate" },
    // { name: "Creativity & Innovation", level: "Intermediate" },
    // { name: "Emotional Intelligence", level: "Intermediate" },
    // { name: "Conflict Resolution", level: "Intermediate" },
    // { name: "Critical Thinking", level: "Advanced" },
];

const transformExperienceData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
        acc[value.explevel] = { ...value, position: Number(key) };
        return acc;
    }, {});
};

const experienceSliderData = transformExperienceData(primarySliderObj);

useEffect(() => {
    fetchJobPoster()
}, [])

function fetchJobPoster(){
    fetchjobposterApi(_jobId, 1).then((_resp) => {
        const record = _resp.data.result[0]
        const soft_skills = record['softSkill']
        setCurrentAddedSkill(soft_skills)
    }).catch((_resp) => {
        console.log(_resp)
    })
}

const addSkill = () => {
    if (softSkillSelectedForInputUI.trim() === "") return;

    if (currentAddedSkill.length >= 10) {
        alert("You can only add up to 10 skills.");
        return;
    }

    setCurrentAddedSkill([...currentAddedSkill, {id: generateHexUUID(), skill: { option: softSkillSelectedForInputUI, explevel: "Entry", exp: "0-2 Years", scrollValue: 0 }}]);
    setSoftSkillSelectedForInputUI('');
    setAddButton(false);
};

const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        event.preventDefault();
        addSkill();
    }
};

function onProceedClick(){
    if(currentAddedSkill.length > 0){
        updateSeekerAssDetails()
    }else{
        toast.warning('Please Select Skills')
        return
    }
}

function updateSeekerAssDetails(){
    const data = {'softSkill': currentAddedSkill}
    updatePosterJobDataByKeyApi(_jobId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('finish', null)
    })
}

function getScrollValue(_exp_level){
    const value = experienceSliderData[_exp_level.toLowerCase()]['position']
    return value
}

function onRangeSliderChange(_new_value, _index) {
    const new_obj = primarySliderObj[_new_value];
    let tmp_arr = [...currentAddedSkill];
    tmp_arr[_index].skill.exp = new_obj.exp;
    tmp_arr[_index].skill.explevel = new_obj.explevel;
    setCurrentAddedSkill(tmp_arr);
}

return (
<section id="soft-skills-steps">
    <BackBtn onClick={() => _onResp("back", null)} />
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-8 text-center skills-step-des">
                    <img src={brand} alt="brand" className="w-80px" />
                    <h3>Add up to 10 soft skills and rate your proficiency in them.</h3>
                </div>
            </div>
        </div>
    </div>
    <div className="container mt-4">
        <div className="row justify-content-center">
            <div className="col-lg-2" />
            <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card">
                                <div className="enter-hard-skills">
                                    <h5>
                                        <strong>Soft skills</strong> are the personal habits and traits that shape how you work with others.
                                    </h5>
                                    <div className="d-flex position-relative">
                                        <input type="text" id="name" name="name" placeholder="Type your hard skills here..."
                                            required="" autoComplete="off" onKeyDown={handleKeyDown} value={softSkillSelectedForInputUI}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const regex = /^[a-zA-Z\s]*$/; // Allow only letters & spaces

                                                if (regex.test(value)) {
                                                    setSoftSkillSelectedForInputUI(value);
                                                    setAddButton(value.trim() !== "");
                                                }
                                            }}
                                        />
                                        {isAddButton && (
                                            <button onClick={addSkill} className="mx-1 btn btn-outline-light text-dark border-dark" type="button">
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="exp-range-slider">
                                    {currentAddedSkill.map((skill, index) => (
                                        <div key={index} className="skill-range-slider">
                                            <h6 className="d-flex justify-content-between text-start fs-6">
                                                <span><b>{skill.skill.option}</b></span>
                                                <span onClick={() => {setCurrentAddedSkill(currentAddedSkill.filter((_, i) => i !== index));}} className="text-danger" style={{'cursor': 'pointer'}}>
                                                    X
                                                </span>
                                            </h6>
                                            <input value={getScrollValue(skill.skill.explevel)} onChange={(e) => onRangeSliderChange(Number(e.target.value), index)} type="range" min="0" max="2" step={1} className="slider form-range"/>
                                            <div className="d-flex justify-content-between">
                                                <span className="capitalize">{skill.skill.explevel}</span>
                                                <span>({skill.skill.exp})</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <label htmlFor="">Examples</label>
                                <ol className="list-group list-group-numbered">
                                    {demoSoftSkills.map((skill, index) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{skill.name}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ol> */}
                            </div>
                            <input type="button" className="next action-button" onClick={() => onProceedClick()} />
                        </fieldset>
                    </form>
                </div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 7/7
                            </h2>
                        </div>
                    </div>
                    <div className='progress border'>
                        <div className='progress-bar' role='progressbar' style={{ width: '100%' }}  aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className="col-lg-2" />
        </div>
    </div>
</section>
);
};

export default SoftSkillsComp;