import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, Link} from "react-router-dom";

import {toast} from "react-toastify";
import {useSelector} from "react-redux";

// import ArrowIcon from "../../../assets/images/right-arrow.png";
// import moment from "moment";
import axios from "axios";
import { updateJobStartDate as updateJobStartDateApi, 
	updateJobTitle as updateJobTitleApi, 
	updateEmploymentType as updateEmploymentTypeApi } from "../../../api/jobs-data";

import EditIcon from "../../../assets/images/edit-icon.png";

import {
	API_GET_POSTER_DETAIL,
	API_PERSONAL_INFO,
	// API_SUBMIT_JOBPOSTER_DATA,
	// API_SUBMIT_JOBSEEKER_DATA,
	API_UPDATE_ACTIVE_STATUS_FOR_JOB,
	BASE_URL,
	postApi,
	postApiPoster,
	API_HARD_STEP_DATA,
	API_SET_POINTS,
	API_UPDATE_JOB_DATA,
	DELETE_JOB,
} from "../../../utils";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
// import SideMenu from "../../../components/SideMenu/sideMenu";
import JobTitleUpdate from "../ProfileView/updateModal/JobTitleUpdate";
import JobRoleUpdate from "../ProfileView/updateModal/JobRoleUpdate";
import IndustryUpdate from "../ProfileView/updateModal/IndustryUpdate";
import EducationUpdate from "../ProfileView/updateModal/EducationUpdate";
import LocationUpdate from "../ProfileView/updateModal/LocationUpdate";
// import CompanySizeUpdate from "../ProfileView/updateModal/CompanySizeUpdate";
import MinPayUpdate from "../ProfileView/updateModal/MinPayUpdate";
import SoftSkillUpdate1 from "../ProfileView/updateModal/SoftSkillUpdate1";
import HardSkillUpdate1 from "../ProfileView/updateModal/HardSkillUpdate1";
import Alert from "sweetalert2";
import GoBack from "../../../components/GoBack";

const  JobDescription = () => {
	const [isLoading, setLoading] = useState(false);
	const {state} = useLocation();
	const employmentTypes = ['Permanent', 'Contract', 'Freelance']

	var jobId_data = localStorage.getItem("jobId");
	if (state) {
		if (state._id !== null) {
			jobId_data = state._id;
		}
	}

	const jobId = jobId_data;
	// console.log(jobId, 'jobId');
	const navigation = useNavigate();
	const userToken = useSelector(
		(state) => state.UserAuth.userData.payload.token
	);
	// console.log(userToken,"TOKEN");
	const userType = useSelector((state) => state.userType.userType);
	const userId = useSelector(
		(state) => state.UserAuth.userData.payload.user._id
	);
	const [jobData, setjobData] = useState([]);
	const [personInfo, setpersonInfo] = useState([]);
	//******Watch the current Index changes
	useEffect(() => {
		if (userType.payload === "jobSeeker") {
			requestDataFromServer1();
		} else {
			requestDataFromServer();
		}
	}, []);
	const requestDataFromServer = () => {
		setLoading(true);
		const obj = {
			usertype: 2,
			userId: userId,
			jobId: jobId,
		};
		postApiPoster(
			API_GET_POSTER_DETAIL,
			obj,
			userToken,
			successFunction,
			errorFunction
		);
	};
	const successFunction = (res) => {
		// console.log(res, "response____");
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;
			// console.log(data, "dataa___");
			data?.map((item) => {
				if (item._id === jobId) {
					if (item?.poster[0].planCredit > 0) {
						setNext("../jobPoster/companies");
					}
					setjobData([item]);
					setJobDataForUpdate(item);
					setPrevSoftSkill(item?.softSkill);
					setPrevHardSkill(item?.hardSkill);
					setelevatorPitchValue(item?.companyDesc);
					setjobDescValue(item?.jobDesc);

					const obj = {result: item?.selfAssessmentResult};
					postApi(API_HARD_STEP_DATA, obj, userToken, successFunctionForkeywords, errorFunction1);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	// console.log(jobData, 'jobdayta');
	const errorFunction = (errr) => {
		setLoading(false);
		toast.error(errr.message);
	};

	const [keywords1, setkeywords1] = useState([]);
	const [keywords2, setkeywords2] = useState([]);

	const successFunctionForkeywords = (res) => {
		// console.log('this is the success result ', res.data);

		setkeywords1(res?.data?.ptb1);

		setkeywords2(res?.data?.whb1 ?? "");
	};
	const [next, setNext] = useState("../paymentOption");
	// console.log('Next Page', next);

	const nextPage = () => {
		navigation(next);
	};

	const requestDataFromServer1 = () => {
		setLoading(true);
		const obj = {
			usertype: 2,
			userId: userId,
		};
		postApiPoster(
			API_PERSONAL_INFO,
			obj,
			userToken,
			successFunction1,
			errorFunction1
		);
	};
	const successFunction1 = (res) => {
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;

			data?.map((item) => {
				if (item._id === jobId) {
					setjobData([item]);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	const errorFunction1 = (errr) => {
		setLoading(false);
		toast.error(errr?.message);
	};

	const [jobDataForUpdate, setJobDataForUpdate] = useState({});

	const [jobRoleModal, setJobRoleModal] = useState(false);
	const [jobTitleModal, setJobTitleModal] = useState(false);
	const [industryModal, setIndustryModal] = useState(false);
	const [educationModal, setEducationModal] = useState(false);
	const [skillLevelModal, setSkillLevelModal] = useState(false);
	const [locationModal, setLocationModal] = useState(false);
	const [companySizeModal, setCompanySizeModal] = useState(false);
	const [employmentTypeModal, setEmploymentTypeModal] = useState(false);
	const [minPayModal, setMinPayModal] = useState(false);
	const [hard1Modal, setHard1Modal] = useState(false);
	const [hard2Modal, setHard2Modal] = useState(false);
	const [soft1Modal, setSoft1Modal] = useState(false);
	const [soft2Modal, setSoft2Modal] = useState(false);
	const [prevSoftSkill, setPrevSoftSkill] = useState([]);
	const [prevHardSkill, setPrevHardSkill] = useState([]);

	const [editElevatorPitch, seteditElevatorPitch] = useState(false);
	const [editJobDesc, seteditJobDesc] = useState(false);

	const updatedSuccesfully = (response) => {
		// console.log("UPDATED SUCCESSFUL::::::", response);
		setLoading(false);
		seteditElevatorPitch(false);
		seteditJobDesc(false);
		const obj = {
			userId: userId,
			id: jobDataForUpdate._id,
		};
		if (response.status === 200) {
			if (userType.payload === "jobSeeker") {
				postApi(API_SET_POINTS, obj, userToken, () => {
				}, () => {
				});
			} else {
				postApiPoster(API_SET_POINTS, obj, userToken, () => {
				}, () => {
				});
			}
			setLoading(false);
			setTimeout(() => {
				requestDataFromServer();
			}, 1000);
		} else {
			setLoading(false);
		}
	};
	const errorWhileUpdate = (response) => {
		console.log("errorWhileUpdate::::::", response);
	};

	const updateProfileFunc = (field, value, field2, value2) => {
		setLoading(true);
		postApi(
			API_UPDATE_JOB_DATA,
			{[field]: value, [field2]: value2, _id: jobId},
			// { ...jobDataForUpdate, [field]: value, [field2]: value2 },
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const [elevatorPitchValue, setelevatorPitchValue] = useState("");

	const updateElevatorPitch = () => {
		setLoading(true);
		postApi(
			BASE_URL + "api/jobposter/updateinfo",
			{userId, companyDesc: elevatorPitchValue},
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const [jobDescValue, setjobDescValue] = useState("");

	const updateJobDesc = (id) => {
		setLoading(true);
		postApi(
			BASE_URL + "api/jobposter/updateJobDescofJob",
			{userId, id, jobDesc: jobDescValue},
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const statusUpdated = (res) => {
		console.log("statusUpdated", res);

		toast("User Status Updated", {
			type: "success",
		});
	};
	const statusError = (error) => {
		console.log("statusError", error);
		toast("User Status Not Updated", {
			type: "error",
		});
	};

	const handleStatusChange = (isChecked) => {
		console.log("isChecked", isChecked);
		postApi(
			API_UPDATE_ACTIVE_STATUS_FOR_JOB,
			{jobId, status: isChecked},
			userToken,
			statusUpdated,
			statusError
		);
	};

	//delete job
	const deleteJob = async (jobId) => {
		Alert.fire({
			title: "Are you sure?",
			text: "You want to delete this job",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const axiosConfig = {
					headers: {
						type: "front-end",
						Authorization: "Bearer " + userToken,
					},
				};
				try {
					const response = await axios.delete(
						`${DELETE_JOB}/${jobId}`,
						axiosConfig
					);
					console.log("response", response)
					if (response.status == 200) {
						Alert.fire("Deleted!", "Your data has been deleted.", "success");
					} else {
						Alert.fire("Oops", "Some error...", "error");
					}
					navigation("/homepage");
				} catch (error) {
					Alert.fire("Oops", "Some error...", "error");
				}
			}
		});
	};

	function onDateChange(_value){
		updateJobStartDateApi(jobId, _value).then((_resp) => {
			if(_resp.data.Result === 'SUCCESS'){
				toast("Start Date Updated", {
					type: "success",
				});
			}else{
				toast("Failed to update start date", {
					type: "error",
				});
			}
		}).catch((_resp) => {
			console.error(_resp)
		}).finally(() => {

		})
	}

	function onJobTitleChange(_index, _title){
		let tmp_data = [...jobData]
		let selected_item = tmp_data[_index]
		selected_item['jobTitle']['option'] = _title
		tmp_data[_index] = selected_item
		setjobData(tmp_data)
		updateJobTitle(_title, selected_item['_id'])
	}

	function onEmploymentTypeChange(_index, _type){
		let tmp_data = [...jobData]
		let selected_item = tmp_data[_index]
		selected_item['employmentType'] = _type
		tmp_data[_index] = selected_item
		setjobData(tmp_data)
		updateEmploymentType(_type, selected_item['_id'])
	}

	function updateJobTitle(_job_title, _job_id){
		updateJobTitleApi(_job_id, _job_title).then((_resp) => {
			// toast.success('Title Changes');
		}).catch((_resp) => {
			console.log()
		}).finally(() => {

		})
	}

	function updateEmploymentType(_type, _job_id){
		updateEmploymentTypeApi(_job_id, _type).then((_resp) => {
			toast.success('Employment Type Updated');
		}).catch((_resp) => {
			console.error(_resp)
		}).finally(() => {

		})
	}

	const renderItem = (item, index) => {
		// const [jobTitle, setJobTitle] = useState(item.jobTitle?.option)
		if (item._id === jobId) {
			const soft_skills = item.softSkill.map((data) => {
				return (
					<li
						style={{
							textAlign: "left",
							listStyle: "none",
						}}
					>
						{data.skill.option} ({data.skill.exp})
					</li>
				);
			});

			const hard_skills = item.hardSkill.map((data) => {
				return (
					<li style={{textAlign: "left", listStyle: "none"}}>
						{data.skill.option} ({data.skill.exp})
					</li>
				);
			});

			return (
				<React.Fragment>
					<GoBack/>
					<div className="col-lg-12 col-sm-12 col-xs-12 mircroshoft-cnt">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="mircroshoft-heading">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<label className="steps-checkbox" style={{float: "right"}}>
											<span>Active</span>
											<input
												onChange={(e) => handleStatusChange(e.target.checked)}
												defaultChecked={item?.status}
												type="checkbox"
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="signup-input-bx col-4">
										<label className="">Job Title</label>
										<input onChange={((e) => onJobTitleChange(index, e.target.value))} value={item.jobTitle?.option} className="form-control" type="text" />
									</div>
									{/* <h6>{item.jobTitle?.option}</h6> */}
								</div>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Job description
									<span onClick={() => seteditJobDesc(!editJobDesc)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
									</span>
									{editJobDesc ? (
										<div>
											<textarea onChange={(e) => setjobDescValue(e.target.value)} defaultValue={item?.jobDesc} type="text" style={{width: "100%", minHeight: "100px"}}/>
											<button onClick={() => updateJobDesc(item._id)} className="form-btn mt-4">
												Update
											</button>
										</div>
									) : (
										<p>{item?.jobDesc}</p>
									)}
								</h5>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									What kind of culture are you hiring for?{" "}
									<span onClick={() => setJobRoleModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
									</span>
								</h5>
								<p>{item?.role?.length ? item.role[0].option : 'No role available'}</p>
								{/* <JobRoleUpdate
									jobRole={item?.role[0].option}
									jobRoleModal={jobRoleModal}
									setJobRoleModal={setJobRoleModal}
									updateProfileFunc={updateProfileFunc}
								/> */}
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									What job title best suits the postion{" "}
									<span onClick={() => setJobTitleModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}} />
									</span>
								</h5>
								<p>{item?.jobTitle.option}</p>
								{/* <JobTitleUpdate
									prevJobTitle={item?.jobtitle[0].option}
									jobTitleModal={jobTitleModal}
									setJobTitleModal={setJobTitleModal}
									updateProfileFunc={updateProfileFunc}
								/> */}
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Industry{" "}
									<span onClick={() => setIndustryModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}} />
									</span>
								</h5>
								<p>{item?.industry[0]?.option}</p>
								<IndustryUpdate
									prevIndustry={item?.industry[0]?.option}
									industryModal={industryModal}
									setIndustryModal={setIndustryModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Education{" "}
									<span onClick={() => setEducationModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}} />
									</span>
								</h5>
								<p>{item?.education[0]?.option}</p>
								{/* <EducationUpdate
									prevEducation={item?.education[0].option}
									educationModal={educationModal}
									setEducationModal={setEducationModal}
									updateProfileFunc={updateProfileFunc}
								/> */}
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Location{" "}
									<span onClick={() => setLocationModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
									</span>
								</h5>
								<p className={'capitalize'}>
									{`${item?.locationName ?? ''}`}
								</p>
								<LocationUpdate
									prevLocation={`${item?.locationName ?? ''}`}
									locationModal={locationModal}
									setLocationModal={setLocationModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Maximum Salary{" "}
									<span onClick={() => setMinPayModal(true)}>
										<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
									</span>
								</h5>
								<p>{item.employmentMode}</p>
								<MinPayUpdate
									prevMinPay={item.employmentMode}
									minPayModal={minPayModal}
									setMinPayModal={setMinPayModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<div className="signup-input-bx col-4">
									<label className="">Employment Type</label>
									<select onChange={(e) => {onEmploymentTypeChange(index, e.target.value)}} value={jobData.employmentType} className='form-select'>
										{employmentTypes.map((x, index) => 
											<option value={x} key={index}>{x}</option>
										)}
									</select>
								</div>
							</div>
							<div className="col-lg-12 mt-2">
								<h5>
									Soft skills
									<span onClick={() => setSoft1Modal(true)}>
							  			<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
									</span>
								</h5>
								<ul style={{paddingLeft: "0px"}}>{soft_skills}</ul>
								<SoftSkillUpdate1
									prevSoftSkill={prevSoftSkill}
									soft1Modal={soft1Modal}
									setSoft1Modal={setSoft1Modal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>

							<div className="col-lg-12 mt-2">
								<h5>
									Hard Skills
									<span onClick={() => setHard1Modal(true)}>
                 	 					<img src={EditIcon} style={{height: "20px", objectFit: "contain"}}/>
               						</span>
								</h5>
								<ul style={{paddingLeft: "0px"}}>{hard_skills}</ul>
								<HardSkillUpdate1
									prevHardSkill={prevHardSkill}
									hard1Modal={hard1Modal}
									setHard1Modal={setHard1Modal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							{jobData.length > 0?
								<div className="col-lg-12 mt-2">
									<h5>Job Start Date</h5>
									<input value={jobData[0].startDate.split('T')[0]} onChange={(e) => {onDateChange(e.target.value)}} className="form-control w-25" type="date"/>
								</div>
							: null}

							{/* <div className="col-lg-12 mt-4">
								<h5>Job Persona</h5>
							</div> */}

							<div className="col-lg-8 mt-2">
								<h5>Description</h5>
								<p>
									Common character traits for individuals who fit the{" "}
									{item?.selfAssessmentResult}. Personality Type Includes:{" "}
									{keywords1?.map((e) => e + ",")}
									They are strong in {keywords2[0] && ""}.
								</p>
							</div>

							<div className="col-lg-8 mt-2">
								<h5>
									<div className="container">
										<div>
											<button
												className="form-btn mt-4 videoUploadBtn btn-warning"
												style={{background: "#ffe923"}}
												onClick={() => {
													navigation("../trackFeedback", {
														state: {feedback: item?.feedback},
													});
												}}
											>
												Track User Feedback
											</button>
										</div>
										<div>
											<button onClick={() => {deleteJob(jobId);}} className="form-btn mt-4 btn-warning" style={{background: "#ff0e0e"}}>
												Delete Job
											</button>
										</div>
									</div>
								</h5>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					<div className="block">
            			<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<>
					<HeaderMenu/>
					<section id="dashboard-cnt-area">
						<div className="container">
							<div className="row dashboard-cnt-row">
								{jobData && jobData?.map((item, index) => renderItem(item, index))}
							</div>
							<div className="row">
								<div className="col-lg-12 text-center">
									<a  onClick={() => nextPage()}>
									</a>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</React.Fragment>
	);
}

export default JobDescription