import { useState, useEffect } from "react";
import { getJobRolesJobRoles as getJobRolesJobRolesApi } from '../../../../api/job-roles';
import LocationSearchInputV2 from "../../locationAutoSuggestion/autosugestionV2";
import { createCompany as createCompanyApi } from "../../../../api/company";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import GoBack from "../../../../components/GoBack"
const CreatePosterCompanyPage = () => {
const navigation = useNavigate();
const base_obj = {
    'name': 'My Company',
    'description': '',
    'culture': '',
    'size': '1-20',
    'location': '',
    'remote': false
}

const companySizes = [
	{id: '1', size: '1-20'},
	{id: '2', size: '21-50'},
	{id: '3', size: '51-100'},
	{id: '4', size: '101-250'},
	{id: '5', size: '251+'},
];
const baseCreateText = 'Create Company'
const [createText, setCreateText] = useState(baseCreateText)
const [data, setData] = useState(base_obj)
const [jobRoles, setJobRoles] = useState([])

useEffect(() => {
    getJobRolesJobRoles()
}, [])

function getJobRolesJobRoles(){
	getJobRolesJobRolesApi().then((_resp) => {
		if(_resp.data.message === 'success'){
            setJobRoles(_resp.data.result)
        }else{

        }
	}).catch((_resp) => {
		console.log(_resp)
	}).finally(() => {

	})
}

function onKeyValueChange(_key, _value){
    if (_key === 'remote') {
        _value = _value === 'true';
    }
    let tmp = {...data};
    tmp[_key] = _value;
    setData(tmp);
}

const getLocationSelected = location => {
    onKeyValueChange('location', location)
};

function onCreateCompClick(){
    const value = sessionStorage.getItem('UserSession');
    const poster_id = JSON.parse(value)['payload']['user']['_id']

    for (const [key, value] of Object.entries(data)) {
        if (value === '') {
            toast.warning(`${key} is Empty`);
            return
        }
    }

    setCreateText('Creating Company')
    createCompanyApi(poster_id, data).then((_resp) => {
        toast.success('Company Created')
        navigation(-1)
    }).catch((_resp) => {

    }).finally(() => {
        setCreateText(baseCreateText)
    })
}

return (<>
    <GoBack/>
    <div className="container mt-4">
        <div className="row d-flex justify-content-center">
            <div className="col-lg-8 text-center mb-3">
                <div className="d-flex justify-content-center">
                    <div style={{ position: 'relative', display: 'inline-block', width: '85px', height: '85px' }}>
                        <img src="" alt={"Company Logo"} style={{  width: '85px',  height: '85px', borderRadius: '75px', border: '1px solid #b8b8b8',objectFit: 'cover'	}} />
                        <span style={{ position: 'absolute', bottom: '0px', right: '0px', background: 'white', borderRadius: '50%', padding: '3px', cursor: 'pointer', boxShadow: '0 0 5px rgba(0,0,0,0.2)'}} onClick={() => document.getElementById('logoFileInput').click()}>
                            <i className="mdi mdi-pencil" style={{ fontSize: '16px', color: '#333' }}>edit</i>
                        </span>
                        <input type="file" id="logoFileInput" style={{ display: 'none' }} accept=".jpg,.png,.jpeg" />
                    </div>
                    <div className='d-flex align-items-center'>
                        <h3 className='mb-0 pb-0 ms-4'><u>{data['name']}</u></h3>
                    </div>
                </div>
            </div>
        </div>
        <div className="row d-flex justify-content-center mt-4">
            <div className="col-8">
                <div>
                    <div className="row mb-3">
                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-nowrap">Name</label>
                        <div className="col-sm-10">
                            <input type="text" id="name" name="name" className="job-input" value={data['name']} placeholder="Company Name" onChange={(e) => {onKeyValueChange('name', e.target.value)}} style={{width: '100%'}}/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">About</label>
                        <div className="col-sm-10">
                            <textarea style={{height: '80px', border: '1px solid #c1c1c1', borderRadius: '5px', fontSize: '16px'}}
                            className={"mb-0 form-control"} placeholder="Tell us more about your company..."
                            value={data['description']} onChange={(e) => onKeyValueChange('description', e.target.value)}/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">Size</label>
                        <div className="col-sm-10">
                            <select value={data.size} onChange={(e) => onKeyValueChange('size', e.target.value)} className='form-select'>
                                {companySizes.map((_x, _i) => 
                                    <option value={_x.size} key={_i}>{_x.size} employees</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">Culture</label>
                        <div className="col-sm-10">
                            <select value={data.culture} onChange={(e) => {onKeyValueChange('culture', e.target.value)}} className='form-select'>
                                <option value="" disabled>Select Culture</option>
                                {jobRoles.map((_x, i) =>
                                    <option value={_x.option} key={i}>{_x.option}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Location</label>
                        <div className="col-sm-10">
                            <div className=''>
                                <LocationSearchInputV2 address={data['location']} onLocationSelected={getLocationSelected} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">Remote</label>
                        <div className="col-sm-10">
                            <select value={data.remote} onChange={(e) => {onKeyValueChange('remote', e.target.value)}} className='form-select'>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-12">
                <button className="perspectv-button" style={{background: '#1ec991'}} onClick={onCreateCompClick}>{createText}</button>
            </div>
        </div>
    </div>
</>)
}

export default CreatePosterCompanyPage