import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
// import { GetProfileData as getProfileDataApi } from "../../../../api/users"
import { fetchjobposter as fetchjobposterApi, 
    updateAssJobData as updateAssJobDataApi } from "../../../../api/jobs-data"
import { getJobRolesByCat as getJobRolesByCatApi } from "../../../../api/job-roles"
import LocationSearchInput from "../../locationAutoSuggestion/autosugestion";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";
import { deleteJob as deleteJobApi } from "../../../../api/jobs-data";
import { getJobById as getJobByIdApi } from "../../../../api/jobs-data";

const ViewEditPosterJobDetails = () => {
const employment_modes_type_values = {
    'hourly': ['£10', '£20', '£30', '£40', '£50', '£60', '£70', '£80', '£90', '£100'],
    'monthly': ['£1000', '£2000', '£3000', '£4000', '£5000', '£6000'],
    'annual': ['£10000', '£20000', '£30000', '£40000', '£50000'],
}
const { job_id } = useParams();
const navigate = useNavigate();
const baseCsvDownloadCsvText = 'Download CV'
const baseUpdateBtn = 'Update'
const baseDeleteBtn = 'Delete'
const companySizes = ['1-20 employee', '21-50 employee', '51-100 employee', '101-250 employee', '251+ employee']
const employmentTypes = ['Permanent', 'Contract', 'Freelance']
const [jobData, setJobData] = useState(null)
const [updateBtnText, setUpdateBtnText] = useState(baseUpdateBtn)
const [deleteBtnText, setDeleteBtnText] = useState(baseDeleteBtn)
const [jobRoles, setJobRoles] = useState([])
const [skillLevels, setSkillLevels] = useState([])
const [educationLevels, setEducationLevels] = useState([])
const [jobIndustries, setJobIndustries] = useState([])
const [csvDownloadCsvText, setCsvDownloadCsvText] = useState(baseCsvDownloadCsvText)
const [userProfile, setUserProfile] = useState(null)
// const [showHardSkillModal, setShowHardSkillModal] = useState(false)
// const [showSoftSkillModal, setShowSoftSkillModal] = useState(false)
const [selectedEmploymentMode, setSelectedEmploymentMode] = useState('')
const [selectedEmploymentModeValue, setSelectedEmploymentModeValue] = useState('')

useEffect(() => {
    getJobData()
    getJobRoles()
    getJobSkillLevels()
    getEduLevels()
    getIndustries()
}, [])


function getJobData(){
    getJobByIdApi(job_id).then((_resp) => {
        const records = _resp.data.result
        if(records.length > 0){
            setJobData(records[0])
            setSelectedEmploymentModes(records[0])
        }
    }).catch((_resp) => {
        console.log(_resp)
    })
}

function setSelectedEmploymentModes(_record){
    const data = _record['employmentMode']
    const employment_mode = data.split(':')[0]
    const employment_value = data.split(':')[1]
    setSelectedEmploymentMode(employment_mode)
    setSelectedEmploymentModeValue(employment_value)
}


function getJobRoles(){
    getJobRolesByCatApi('jobrole', 1, 'front-end').then((_resp) => {
        let data = _resp.data.result
        const options = data.map(x => x.option.toLowerCase())
        setJobRoles(options)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function getJobSkillLevels(){
    getJobRolesByCatApi('skilllevel', 1, 'front-end').then((_resp) => {
        let data = _resp.data.result
        const options = data.map(x => x.option.toLowerCase())
        setSkillLevels(options)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function getEduLevels(){
    getJobRolesByCatApi('educationlevel', 1, 'front-end').then((_resp) => {
        let data = _resp.data.result
        const options = data.map(x => x.option.toLowerCase())
        setEducationLevels(options)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function getIndustries(){
    getJobRolesByCatApi('jobindustry', 1, 'front-end').then((_resp) => {
        let data = _resp.data.result
        const options = data.map(x => x.option.toLowerCase())
        setJobIndustries(options)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function onKeyValueChange(_key, _value){
    let tmp = {...jobData}
    tmp[_key] = _value
    setJobData(tmp)
}

function onSubKeyValueChange(_key, _sub_key, _value){
    let tmp = {...jobData}
    if(['industry', 'role'].includes(_key)){
        tmp[_key][0][_sub_key] = _value
    }else{
        tmp[_key][_sub_key] = _value
    }
    setJobData(tmp)
}

function onEmploymentModeChange(_type, _value){
    let x = ``
    if(_type === 'mode'){
        x = `${_value}:${selectedEmploymentModeValue}`
        setSelectedEmploymentMode(_value)
    }else if(_type === 'value'){
        x = `${selectedEmploymentMode}:${_value}`
        setSelectedEmploymentModeValue(_value)
    }
    onKeyValueChange('employmentMode', x)
}

function onUpdateClick(){
    setUpdateBtnText('Updating...')
    updateAssJobDataApi(jobData).then((_resp) => {
    if(_resp.data.Result === 'SUCCESS'){
        toast.success("Details Updated", { position: "top-right", autoClose: 1000 });
    }else{

    }
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        setUpdateBtnText(baseUpdateBtn)
    })
}

const handleLocationSelected = location => {
    onKeyValueChange('locationName', location)
};

function onAddHardSkillClick(){
    // setShowHardSkillModal(true)
}

function onAddSoftSkillClick(){
    // setShowSoftSkillModal(true)
}

function onFinishClick(){
    // _onResp('next', null)
    navigate(`/jobPoster/company/${jobData['companyId']}`)
}

function onDeleteJobClick(){
    setDeleteBtnText('Deleting...')
    deleteJobApi(job_id).then((_resp) => {
        if(_resp.data.Resp.Result === 'SUCCESS'){
            toast.success('Job Removed')
            onFinishClick()
        }else{
            toast.warning('Job Removed')
        }
    }).catch((_resp) => {
        console.log(_resp)
    }).finally(() => {
        setDeleteBtnText(baseDeleteBtn)
    })
}

return <>
<section id="skills-steps">
    {/* <BackBtn onClick={() => {}} /> */}
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-8 text-center skills-step-des">
                    <img src={brand} alt="brand" className="w-80px" />
                    <h3 className="job-que mb-4">
                        Job Summary
                    </h3>
                </div>
            </div>
        </div>
    </div>
    {jobData !== null?
        <section className='container pt-0 pb-0' id="dashboard-cnt-area">
            <div className="ms-4 me-4 mb-4 d-flex align-items-center">
                <div>
                    <button onClick={onFinishClick} className='form-btn float-end'>All Jobs</button>
                </div>
            </div>
            <div className="row m-4 pt-4">
                <div className="col-lg-4 col-xs-12 mb-2">
                    <div className="card shadow">
                        <div className="list-group">
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Job Title</label>
                                    <input  onChange={(e) => {onSubKeyValueChange('jobTitle', 'option', e.target.value)}} value={jobData.jobTitle.option} type="text" />
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Industry</label>
                                    <input  onChange={(e) => onSubKeyValueChange('industry', 'option', e.target.value)} 
                                        value={jobData.industry?.[0]?.option || ''} type="text" />  
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Employment Type</label>
                                    <select  onChange={(e) => {onKeyValueChange('employmentType', e.target.value)}} value={jobData.employmentType} className='form-select'>
                                        {employmentTypes.map((x, index) => 
                                        <option value={x} key={index}>{x}</option>
                                        )}
                                    </select>
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Employment Mode</label>
                                    <select  onChange={(e) => onEmploymentModeChange('mode', e.target.value)} value={selectedEmploymentMode} className='form-select'>
                                        <option value="hourly-rate">Hourly</option>
                                        <option value="monthly">Monthly</option>
                                        <option value="annual">Annual</option>
                                    </select>
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Employment Mode Value</label>
                                    {selectedEmploymentMode !== ''?
                                        <select  onChange={(e) => onEmploymentModeChange('value', e.target.value)} value={selectedEmploymentModeValue} className='form-select'>
                                        {employment_modes_type_values[selectedEmploymentMode].map((x, index) => 
                                            <option key={index} value={x}>{x}</option>
                                        )}
                                        </select>
                                    : null}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="card shadow">
                        <div className="list-group">
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Location</label>
                                    <LocationSearchInput address={jobData.locationName} onLocationSelected={handleLocationSelected} />
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Remote Work</label>
                                    <select onChange={(e) => onKeyValueChange('remotely', e.target.value === 'true')} value={jobData.remotely} className="form-select">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Education Level</label>
                                    <select  onChange={(e) => {onSubKeyValueChange('educationLevel', 'option', e.target.value)}} value={jobData.educationLevel.option} className='form-select'>
                                        {educationLevels.map((x, index) => 
                                        <option value={x} key={index}>{x}</option>
                                        )}
                                    </select>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12 mb-2">
                    <div className="card shadow">
                        <div className="list-group">
                            <a className="list-group-item border-0" >
                                <div className="signup-input-bx">
                                    <label className="">Skill Level</label>
                                    <select  onChange={(e) => {onSubKeyValueChange('skillLevel', 'option', e.target.value)}} value={jobData.skillLevel.option.toLowerCase()} className='form-select'>
                                        {skillLevels.map((x, index) => 
                                        <option value={x} key={index}>{x}</option>
                                        )}
                                    </select>
                                {/* <input onChange={(e) => {onSubKeyValueChange('skillLevel', 'option', e.target.value)}} value={jobData.skillLevel.option} type="text" /> */}
                                </div>
                            </a>
                            <a className="list-group-item border-0" >
                                <div className="d-flex w-100 justify-content-between">
                                    <span className="mb-1">Soft Skills - {jobData.softSkill.length}</span>
                                    {/* <i onClick={onAddSoftSkillClick} className='fa fa-edit' type={'button'}></i> */}
                                </div>
                                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <ul className="text-start">
                                        {jobData.softSkill.map((sk, index) => (
                                            <li key={index} className="text-start">{sk.skill.option}</li>
                                        ))}
                                    </ul>
                                </div>
                            </a>
                            <a className="list-group-item border-0">
                                <div className="d-flex w-100 justify-content-between">
                                    <span className="mb-1">Hard Skills - {jobData.hardSkill.length}</span>
                                    {/* <i onClick={onAddHardSkillClick} className="fa fa-edit" type="button"></i> */}
                                </div>
                                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <ul className="text-start">
                                        {jobData.hardSkill.map((sk, index) => (
                                            <li key={index} className="text-start">{sk.skill.option}</li>
                                        ))}
                                    </ul>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-4">
            <div className="col-12">
                    <div className="card text-start">
                        <span className="text-dark opacity-50">Job Description</span>
                        <p className='text-start fs-6 p-4 pb-0 pt-0 text-dark' style={{'lineHeight': '1.5'}}>
                            {(jobData['jobDesc']?.replace(/\s+/g, ' ').replace(/\.\s+/g, '.\n\n').trim()) || ''}
                        </p>
                    </div>
                </div>
            </div>
            <hr />
            <div className='d-flex justify-content-between'>
                <button onClick={onDeleteJobClick} disabled={deleteBtnText !== baseDeleteBtn} style={{backgroundColor: 'red'}} id='btn-delete-user-data' className='form-btn float-end'>{deleteBtnText}</button>
                <button onClick={onUpdateClick} disabled={updateBtnText !== baseUpdateBtn} id='btn-update-user-data' className='form-btn float-end'>{updateBtnText}</button>
            </div>
        </section>
    : 
        <>
            <h2>Loading...</h2>
        </>
    }
</section>
</>
}

export default ViewEditPosterJobDetails