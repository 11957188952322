import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getJobById as fetchjobposterApi } from "../../../../api/jobs-data";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { generateHexUUID } from "../../../../utils";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";

const HardSkillsStepComp = ({_jobId, _onResp }) => {
const [hardSkillForInputUI, setHardSkillForInputUI] = useState('');
const [isAddButton, setAddButton] = useState(false);
const [currentAddedSkill, setCurrentAddedSkill] = useState([]);

const primarySliderObj = {
    0: { exp: "0-2 years", explevel: "entry" },
    1: { exp: "2-5 years", explevel: "senior" },
    2: { exp: "5-10 years", explevel: "expert" }
};

const demoSkills = [
    // { name: "Project Management", level: "Advanced" },
    // { name: "Data Analysis & Interpretation", level: "Advanced" },
    // { name: "Software Development", level: "Expert" },
    // { name: "Technical Writing & Documentation", level: "Advanced" },
    // { name: "Cloud Computing & Infrastructure Management", level: "Advanced" },
    // { name: "Marketing & SEO Optimization", level: "Intermediate" },
    // { name: "Financial Management & Budgeting", level: "Intermediate" },
    // { name: "Graphic Design & UI/UX", level: "Intermediate" },
    // { name: "Sales & Business Development", level: "Intermediate" },
    // { name: "Machine Learning & AI Implementation", level: "Intermediate" },
];

const transformExperienceData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
        acc[value.explevel] = { ...value, position: Number(key) };
        return acc;
    }, {});
};

const experienceSliderData = transformExperienceData(primarySliderObj);

const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        event.preventDefault();
        addSkill();
    }
};

useEffect(() => {
    fetchJobPoster()
}, [])

function fetchJobPoster(){
    fetchjobposterApi(_jobId).then((_resp) => {
        const record = _resp.data.result[0]
        const hard_skills = record['hardSkill']
        setCurrentAddedSkill(hard_skills)
    }).catch((_resp) => {

    })
}

const addSkill = () => {
    if (hardSkillForInputUI.trim() === "") return;

    if (currentAddedSkill.length >= 10) {
        alert("You can only add up to 10 skills.");
        return;
    }

    setCurrentAddedSkill([...currentAddedSkill, {id: generateHexUUID(), skill: { option: hardSkillForInputUI, explevel: "Entry", exp: "0-2", scrollValue: 0 }}]);
    setHardSkillForInputUI('');
    setAddButton(false);
};

const removeSkill = (index) => {
    const updatedSkills = currentAddedSkill.filter((_, i) => i !== index);
    setCurrentAddedSkill(updatedSkills);
};

function onProceedClick(){
    if(currentAddedSkill.length > 0){
        updateSeekerAssDetails()
        _onResp('next', null)
    }else{
        toast.warning('Please Select Skills')
        return
    }
}

function updateSeekerAssDetails(){
    const data = {'hardSkill': currentAddedSkill}
    updatePosterJobDataByKeyApi(_jobId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        
    })
}

function getScrollValue(_exp_level){
    const value = experienceSliderData[_exp_level.toLowerCase()]['position']
    return value
}

function onRangeSliderChange(_new_value, _index) {
    const new_obj = primarySliderObj[_new_value];
    let tmp_arr = [...currentAddedSkill];
    tmp_arr[_index].skill.exp = new_obj.exp;
    tmp_arr[_index].skill.explevel = new_obj.explevel;
    setCurrentAddedSkill(tmp_arr);
}

return (
<section id="skills-steps">
    <BackBtn onClick={() => _onResp("back", null)} />
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-8 text-center skills-step-des">
                    <img src={brand} alt="brand" className="w-80px" />
                    <h3 className="job-que">
                        Add up to 10 hard skills and rate your proficiency in them
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-2" />
            <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card">
                                <div className="enter-hard-skills">
                                    <h5>
                                        <strong>Hard skills</strong> are the specific skills you use on the job.
                                    </h5>
                                    <div className="d-flex position-relative">
                                        <input type="text" id="name" name="name" placeholder="Type your hard skills here..."
                                            required="" autoComplete="off" onKeyDown={handleKeyDown} value={hardSkillForInputUI}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const regex = /^[a-zA-Z\s]*$/; // Allow only letters & spaces

                                                if (regex.test(value)) {
                                                    setHardSkillForInputUI(value);
                                                    setAddButton(value.trim() !== "");
                                                }
                                            }}
                                        />
                                        {isAddButton && (
                                            <button onClick={addSkill} className="mx-1 btn btn-outline-light text-dark border-dark" type="button">
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="exp-range-slider" style={{'maxHeight': '350px', overflow: 'auto'}}>
                                    {currentAddedSkill.map((data, index) => (
                                        <div key={index} className="skill-range-slider">
                                            <div className="slidecontainer">
                                                <h6 className="d-flex justify-content-between text-start fs-6">
                                                    <span><b>{data.skill.option}</b></span>
                                                    <span onClick={() => {setCurrentAddedSkill(currentAddedSkill.filter((_, i) => i !== index));}} className="text-danger" style={{'cursor': 'pointer'}}>
                                                        X
                                                    </span>
                                                </h6>
                                                <input value={getScrollValue(data.skill.explevel)} onChange={(e) => onRangeSliderChange(Number(e.target.value), index)} type="range" min="0" max="2" step={1} className="slider form-range"/>
                                                <div className="d-flex justify-content-between">
                                                    <span className="capitalize">{data.skill.explevel}</span>
                                                    <span>({data.skill.exp})</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <label htmlFor="">Examples</label>
                                <ol className="list-group list-group-numbered">
                                    {demoSkills.map((skill, index) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{skill.name}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ol> */}
                            </div>
                            <input type="button" name="next" className="next action-button" value="" onClick={() => {onProceedClick()}} />
                        </fieldset>
                    </form>
                </div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 6/7
                            </h2>
                        </div>
                    </div>
                    <div className='progress border'>
                        <div className='progress-bar' role='progressbar' style={{ width: '87.50%' }}  aria-valuenow='87.50' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className="col-lg-2" />
        </div>
    </div>
</section>
);
};

export default HardSkillsStepComp;