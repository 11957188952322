import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";
import { uploadJobPosterJobDescFile as uploadJobPosterJobDescFileApi } from "../../../../api/poster";

const SetDateAndDescriptionComp = ({_jobId, _onResp}) => {
const [startDate, setStartDate] = useState('')
const [description, setDescription] = useState('')
const [showFormInput, setShowFormInput] = useState(true)
const [file, setFile] = useState(null)

function onFinishClick(){
    if(startDate === ''){
        toast.warning('Please Select Start Date')
        return
    }

    if(showFormInput === true){
        if(file === null){
            toast.warning('Please Provide File')
            return
        }
        const obj = {'startDate': `${startDate}T00:00:00.000+00:00` }
        updatePosterJobDataByKey(obj)
        uploadJobDescFile()
    }else{
        if(description.length < 10){
            toast.warning('Job Description To Short')
            return
        }
        const obj = {
            'startDate': `${startDate}T00:00:00.000+00:00`,
            'jobDesc': description
        }
        updatePosterJobDataByKey(obj)
    }
    
}

function updatePosterJobDataByKey(obj){
    updatePosterJobDataByKeyApi(_jobId, obj).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

function uploadJobDescFile(){
    uploadJobPosterJobDescFileApi(_jobId, file).then((_resp) => {

    }).catch(() => {

    })
}

return (<>
<section id="skills-steps">
    <BackBtn onClick={() => _onResp("back", null)} />
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-8 text-center skills-step-des">
                    <img src={brand} alt="brand" className="w-80px" />
                    <h3 className="job-que mb-4">
                        Job Description
                    </h3>
                </div>
            </div>
        </div>
        {/* <button onClick={getJobData}>refresh</button> */}
    </div>
    <section className='container pt-0 pb-0' id="dashboard-cnt-area">
            <div className='row justify-content-center'>
                <div className='col-lg-2' />
                <div className='col-lg-8'>
                    <div className="card">
                        <div id="msform">
                            <fieldset>
                                <div className='form-card'>
                                    <div>
                                        <h5 className="text-white">Job Start Date</h5>
                                        <div className="d-flex justify-content-center">
                                            <input value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control w-50" type="date" />
                                        </div>
                                    </div>
                                    <br />
                                    <div>
                                        <h5 className="text-white mt-4 mb-2">Job Description</h5>
                                        {showFormInput === true?
                                            <div>
                                                <input onChange={(e) => {setFile(e.target.files[0])}} type="file" className="form-control" />
                                            </div>
                                        :
                                        <div>
                                            <textarea onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Job Description..." style={{'height': '200px'}} className="form-control mb-4"/>
                                        </div>
                                        }
                                        <div className="mt-4">
                                            <label onClick={() => {setShowFormInput(!showFormInput)}} style={{'cursor': 'pointer'}}>
                                                {showFormInput === true?
                                                    <span>Enter Job Description</span>
                                                    :
                                                    <span>Provide Description File</span>
                                                }
                                            </label>
                                        </div>
                                    </div>
                                    <div className="m-4 d-flex justify-content-center align-items-center">
                                        <div>
                                            <button onClick={onFinishClick} className='form-btn float-end'>next</button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                    Step 2/7
                                </h2>
                            </div>
                        </div>
                        <div className='progress'>
                            <div className='progress-bar' role='progressbar' style={{ width: '22%' }}  aria-valuenow='2' aria-valuemin='0' aria-valuemax='100' />
                        </div>
                    </div>
                </div>
                <div className='col-lg-2' />
            </div>
    </section>
</section>
</>)
}

export default SetDateAndDescriptionComp