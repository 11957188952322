import { postData } from "./requests";

export const updateJobStartDate = (_job_id, _new_date) => {
    const url = 'api/jobposter/update/job-start-date';
    const requestData = {
        'job_id': _job_id,
        'start_date': _new_date
    };
    return postData(url, requestData)
}

export const fetchjobposter = (_user_id, _user_type) => {
    const url = 'api/jobposter/fetchjobposter';
    const requestData = {
        'userId': _user_id,
        'usertype': _user_type
    };
    return postData(url, requestData)
}

export const getJobById = (_job_id) => {
    const url = 'api/jobposter/fetchjobposter';
    const requestData = {
        'jobId': _job_id,
        'userId': null
    };
    return postData(url, requestData)
}

export const updateAssJobData = (_record) => {
    const url = 'api/jobposter/update-job-data-v2';
    const requestData = {
        'record': _record,
    };
    return postData(url, requestData)
}

export const updateJobTitle = (_job_id, _job_title) => {
    const url = 'api/jobposter/update-job-title';
    const requestData = {
        'job_id': _job_id,
        'job_title': _job_title
    };
    return postData(url, requestData)
}

export const updateEmploymentType = (_job_id, _type) => {
    const url = 'api/jobposter/update-employment-type';
    const requestData = {
        'job_id': _job_id,
        'type': _type
    };
    return postData(url, requestData)
}

export const deleteJob = (_job_id) => {
    const url = 'api/job-poster/job/delete';
    const requestData = {
        'job_id': _job_id
    };
    return postData(url, requestData)
}