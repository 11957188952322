import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import GoBack from "../../../../components/GoBack";
import LocationSearchInputV2 from "../../locationAutoSuggestion/autosugestionV2";
import {API_JOBPOSTER_COMPANY_ADD_OR_EDIT, API_JOBPOSTER_COMPANY_DELETE, postApiPosterMultipart} from "../../../../utils";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import { getJobRolesJobRoles as getJobRolesJobRolesApi } from '../../../../api/job-roles';

const AddOrEditCompanyPage = () => {
const navigate = useNavigate();
const {state} = useLocation();

const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);

const [isLoading, setIsLoading] = useState(false);

const [companyId, setCompanyId] = useState('');
const [companyName, setCompanyName] = useState('');
const [companyDescription, setCompanyDescription] = useState('');
const [companySize, setCompanySize] = useState([]);
const [listedCompanySize, setListedCompanySize] = useState('');
const [locationSelected, setLocationSelected] = useState('');
const [fileName, setFileName] = useState("");
const [documentSelected, setDocumentSelected] = useState("");
const [jobRoles, setJobRoles] = useState([])
const [remote, setRemote] = useState(false)
const [jobRoleSelected, setJobRoleSelected] = useState([])
const [userCreatedRole, setUserCreatedRole] = useState('')

const companySizes = [
	{id: '1', size: '1-20'},
	{id: '2', size: '21-50'},
	{id: '3', size: '51-100'},
	{id: '4', size: '101-250'},
	{id: '5', size: '251+'},
];

useEffect(() => {
	setListedCompanySize(companySizes);
	getJobRolesJobRoles()
	if(state) {
		setCompanyId(state._id);
		setCompanyName(state.name);
		setCompanyDescription(state.description);
		setCompanySize(companySizes.filter(e => e.size === state.size)[0].id);
		setLocationSelected(state.location);
		setRemote(state?.remote ?? false);
		setUserCreatedRole(state?.culture ?? '')
	}
}, []);

function getJobRolesJobRoles(){
	getJobRolesJobRolesApi().then((_resp) => {
		if(_resp.data.message === 'success'){
            setJobRoles(_resp.data.result)
        }else{

        }
	}).catch((_resp) => {
		console.log(_resp)
	}).finally(() => {

	})
}

const getLocationSelected = location => {
	setLocationSelected(location);
};

const getFileSelected = (e) => {
	const fileName = e.target.files[0].name;
	const fileSelected = e.target.files[0];
	setFileName(fileName);
	setDocumentSelected(fileSelected);
};

const addCompany = () => {
	if(userCreatedRole === ''){
		toast.warning('Please select culture')
		return
	}
	if((companyId && companyName && companyDescription && companySize && locationSelected) || ((!companyId) && companyName && companyDescription && companySize && locationSelected)) {
		const theSize = companySizes.filter(e => e.id == companySize)[0].size;

		var formdata = new FormData();
		formdata.append("userId", userId);
		if(companyId) {
			formdata.append("companyId", companyId);
		}
		formdata.append("companyName", companyName);
		formdata.append("companyDescription", companyDescription);
		formdata.append("companySize", theSize);
		formdata.append("companyLocation", locationSelected);
		formdata.append("companyCulture", userCreatedRole);
		formdata.append("remote", remote)
		if(documentSelected) {
			formdata.append("companyLogo", documentSelected);
		}

		postApiPosterMultipart(
			API_JOBPOSTER_COMPANY_ADD_OR_EDIT,
			formdata,
			userToken,
			companyAddSuccessful,
			companyAddError
		);
	} else {
		toast.error('Oops! something went wrong!', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
};

const companyAddSuccessful = () => {
	navigate(companyId ? "../companies" : "../companies");
};

const companyAddError = () => {
	toast.error('Oops! something went wrong!', {
		position: 'top-right',
		autoClose: 1000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

const confirmDeleteCompany = () => {
	Swal.fire({
		title: "Are you sure?",
		text: "You won't be able to revert this!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: "Yes"
	}).then((result) => {
		if (result.isConfirmed) {
			deleteCompany();
		}
	});
};

const deleteCompany = () => {
	setIsLoading(true);
	postApiPosterMultipart(
		API_JOBPOSTER_COMPANY_DELETE,
		{"userId": userId, "companyId": companyId},
		userToken,
		companyDeleteSuccessful,
		companyDeleteError
	);
};

const companyDeleteSuccessful = () => {
	navigate("../../");
	toast.success('Company deleted!', {
		position: 'top-right',
		autoClose: 1000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

const companyDeleteError = () => {
	toast.error('Oops! something went wrong!', {
		position: 'top-right',
		autoClose: 1000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
	setIsLoading(false);
};

return (
<React.Fragment>
	{isLoading && (
		<div className="d-flex justify-content-center align-items-center LoaderCenter">
			<div className="block">
				<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
				<h6 className="LoaderText">Processing...</h6>
			</div>
		</div>
	)}
	{!isLoading && (
		<div>
			<GoBack></GoBack>
			<div className="container mt-4">
				<div className="row d-flex justify-content-center">
					<div className="col-lg-8 text-center mb-3">
						<div className="d-flex justify-content-center">
							<div style={{ position: 'relative', display: 'inline-block', width: '85px', height: '85px' }}>
								<img src={state.logo || 'idk'} alt={"Company Logo"} style={{  width: '85px',  height: '85px', borderRadius: '75px', border: '1px solid #b8b8b8',objectFit: 'cover'	}} />
								<span style={{ position: 'absolute', bottom: '0px', right: '0px', background: 'white', borderRadius: '50%', padding: '3px', cursor: 'pointer', boxShadow: '0 0 5px rgba(0,0,0,0.2)'}} onClick={() => document.getElementById('logoFileInput').click()}>
									<i className="mdi mdi-pencil" style={{ fontSize: '16px', color: '#333' }}>edit</i>
								</span>
								<input type="file" id="logoFileInput" style={{ display: 'none' }} accept=".jpg,.png,.jpeg" onChange={(e) => getFileSelected(e)} />
							</div>
							<div className='d-flex align-items-center'>
								<h3 className='mb-0 pb-0 ms-4'><u>{companyName}</u></h3>
							</div>
						</div>
					</div>
				</div>
				<div className="row d-flex justify-content-center mt-4">
					<div className="col-8">
						<div>
							<div className="row mb-3">
								<label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-nowrap">Name</label>
								<div className="col-sm-10">
									<input type="text" id="name" name="name" className="job-input" value={companyName} onFocus={event => {event.target.setAttribute('autocomplete', 'off');}} placeholder="Company Name" onChange={event => {setCompanyName(event.target.value);}} style={{width: '100%'}}/>
								</div>
							</div>

							<div className="row mb-3">
								<label htmlFor="inputPassword3" className="col-sm-2 col-form-label">About</label>
								<div className="col-sm-10">
									<textarea style={{height: '80px', border: '1px solid #c1c1c1', borderRadius: '5px', fontSize: '16px'}}
									className={"mb-0 form-control"} placeholder="Tell us more about your company..."
									value={companyDescription} onChange={(e) => setCompanyDescription(e.target.value)}/>
								</div>
							</div>

							<div className="row mb-3">
								<label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Size</label>
								<div className="col-sm-10">
									<select className='form-select'>
										{companySizes.map((_x, _i) => 
											<option value="" key={_i}>{_x.size} employees</option>
										)}
									</select>
								</div>
							</div>

							<div className="row mb-3">
								<label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Culture</label>
								<div className="col-sm-10">
									<select value={userCreatedRole} onChange={(e) => {setUserCreatedRole(e.target.value)}} className='form-select'>
										<option value="" disabled>Select Culture</option>
										{jobRoles.map((_x, i) =>
											<option value={_x.option} key={i}>{_x.option}</option>
										)}
									</select>
								</div>
							</div>

							<div className="row mb-3">
								<label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Location</label>
								<div className="col-sm-10">
									<div className='' style={{transform: 'translateY(15%)', marginTop: '0'}}>
										<LocationSearchInputV2 address={locationSelected} onLocationSelected={getLocationSelected} />
									</div>
								</div>
							</div>

							<div className="row mb-3">
								<label className="col-sm-2 col-form-label">Remote</label>
								<div className="col-sm-10">
									<select value={remote === true ? "true" : "false"} onChange={(e) => setRemote(e.target.value === "true")} className="form-select">
										<option value="true">Yes</option>
										<option value="false">No</option>
									</select>
								</div>
							</div>

							{/* <button type="submit" className="btn btn-primary">Sign in</button> */}
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12">
						<button className="perspectv-button" style={{background: '#1ec991'}} onClick={addCompany}>{companyId ? 'Edit' : 'Add'} Company</button>
						<div style={{display: "inline-block", width: "20px"}}></div>
						{companyId ? <button className="perspectv-button" style={{background: '#ff0e0e'}} onClick={confirmDeleteCompany}>Delete Company</button> : null}
					</div>
				</div>
			</div>
		</div>
	)}
</React.Fragment>
);
}

export default AddOrEditCompanyPage