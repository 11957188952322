import { useState } from "react";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";

const SalaryComp = ({_jobId, _onResp}) => {
const [isRadioChecked, setRadioChecked] = useState(undefined);
const [rangeValueHourly, setRangeValueHourly] = useState(10);
const [rangeValueMonthly, setRangeValueMonthly] = useState(1000);
const [rangeValueAnnual, setRangeValueAnnual] = useState(20000);
const [employmentLength, setEmploymentLength] = useState([]);
const [selectedItem, setSelectedItem] = useState("£");

function onProceedClick(){
    let value = ''
    if(isRadioChecked === 1){
        value = `hourly:£${rangeValueHourly}`
    }else if(isRadioChecked === 2){
        value = `monthly:£${rangeValueMonthly}`
    }else if(isRadioChecked === 3){
        value = `annual:£${rangeValueAnnual}`
    }else{}

    if(employmentLength.length === 0){
        toast.warning('Please Select Employment Type')
        return
    }else if([undefined, null, ''].includes(value)){
        toast.warning('Please Select Value')
        return
    }else{
        updateSeekerAssDetails(value, employmentLength)
    }
}

function updateSeekerAssDetails(_value, _type){
    const data = {
        'employmentMode': _value,
        'employmentType': _type
    }
    updatePosterJobDataByKeyApi(_jobId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

return <>

<section id='job-role-steps'>
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className='discover-personality-top-cnt'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <img src={brand} alt='brand' className='w-80px' />
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-2' />
            <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                    <form id='msform'>
                    {/* <!-- progressbar --> */}
                        <fieldset>
                            <div className='form-card'>
                                <h3 className="job-que">What type of employment are you looking for?</h3>
                                <div className='role-wrapper skyblue-radio-btn'>
                                    <div className='role-col'>
                                        <div className='inputGroup'>
                                            <input id='radio49' name='eradio' type='radio'  onChange={() => setEmploymentLength('Permanent')} />
                                            <label htmlFor='radio49'>Permanent</label>
                                        </div>
                                    </div>
                                    {/* <!--role-col--> */}

                                    <div className='role-col'>
                                        <div className='inputGroup'>
                                            <input id='radio50' name='eradio' type='radio' onChange={() => setEmploymentLength('Contract')} />
                                            <label htmlFor='radio50'>Contract</label>
                                        </div>
                                    </div>
                                    {/* <!--role-col--> */}

                                    <div className='role-col'>
                                        <div className='inputGroup'>
                                            <input id='radio51' name='eradio' type='radio' onChange={() => setEmploymentLength('Freelance')} />
                                            <label htmlFor='radio51'>Freelance</label>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="job-que">Select your pay structure and your minimum salary expectation?</h3>

                                <div className='role-wrapper'>
                                    <div className='role-col' onClick={() => setRadioChecked(1)}>
                                        <div className='inputGroup'>
                                            <input id='radio53' name='radio' type='radio' />
                                            <label htmlFor='radio53'>Hourly Rate</label>
                                        </div>
                                    </div>
                                    {/* <!--role-col--> */}

                                    <div className='role-col' onClick={() => setRadioChecked(2)}>
                                        <div className='inputGroup'>
                                            <input id='radio54' name='radio' type='radio' />
                                            <label htmlFor='radio54'>Monthly</label>
                                        </div>
                                    </div>
                                    {/* <!--role-col--> */}

                                    <div className='role-col' onClick={() => setRadioChecked(3)}>
                                        <div className='inputGroup'>
                                            <input id='radio55' name='radio' type='radio' />
                                            <label htmlFor='radio55'>Annual</label>
                                        </div>
                                    </div>

                                    <div className='range-slider'>
                                    {isRadioChecked === 1 && (
                                        <div className='slidecontainer'>
                                            <input type='range' min='10' max='100' defaultValue={rangeValueHourly}
                                                onChange={e => setRangeValueHourly(e.target.value)} className='slider form-range'
                                                id='customRange2' step='10' disabled={isRadioChecked === undefined}/>
                                            <p className='col-12 d-flex mt-4' >
                                                <h2>{selectedItem}{rangeValueHourly}</h2>
                                            </p>
                                        </div>
                                    )}
                                    {isRadioChecked === 2 && (
                                        <div className='slidecontainer'>
                                            <input type='range' min='1000' max='20000' defaultValue={rangeValueMonthly} 
                                                onChange={e => setRangeValueMonthly(e.target.value)} className='slider form-range'
                                                id='customRange2' step='1000' disabled={isRadioChecked === undefined}/>
                                            <p className='col-12 d-flex mt-4' >
                                                <h2>{selectedItem}{rangeValueMonthly}</h2>
                                            </p>
                                        </div>
                                    )}
                                    {isRadioChecked === 3 && (
                                        <div className='slidecontainer'>
                                            <input type='range' min='20000' max='120000' defaultValue={rangeValueAnnual} onChange={e => setRangeValueAnnual(e.target.value)} className='slider form-range' id='customRange2' step='10000' disabled={isRadioChecked === undefined} />
                                            <p className='col-12 d-flex mt-4' >
                                                <h2>{selectedItem}{rangeValueAnnual}</h2>
                                            </p>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>

                            <input type='button' name='next' className='next action-button mt-0' value='' onClick={() => onProceedClick()}/>
                        </fieldset>
                    </form>
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                    Step 5/7
                                </h2>
                            </div>
                        </div>
                        <div className='progress border'>
                            <div className='progress-bar' role='progressbar' style={{ width: '70%' }}  aria-valuenow='70' aria-valuemin='0' aria-valuemax='100' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-2' />
        </div>
    </div>
</section>
</>
}

export default SalaryComp