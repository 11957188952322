import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { getJobRolesJobTitles as getJobRolesJobTitlesApi } from "../../../../api/job-roles";
import { createPosterJobByJobTitle as createPosterJobByJobTitleApi, 
    updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";
import { generateHexUUID } from "../../../../utils";

const JobTitleComp = ({_companyId, _jobId, _onResp}) => {
const sessionDataJson = sessionStorage.getItem('UserSession');
const sessionData = JSON.parse(sessionDataJson)
const userData = sessionData['payload']['user']
const [jobListed, setJobListed] = useState([])
const [roleSelected, setRoleSelected] = useState('')
const [jobTitleForTextField, setjobTitleForTextField] = useState('')
const [userCreatedRole, setUserCreatedRole] = useState('')
const [preferredJobTitle, setPreferredJobTitle] = useState('')

useEffect(() => {
    getJobRoles()
}, [])

function getJobRoles(){
    getJobRolesJobTitlesApi().then((_resp) => {
        if(_resp.data.message === 'success'){
            setJobListed(_resp.data.result)
        }else{

        }
    }).catch(() => {

    })
}

function onProceedClick() {
    if([undefined, null, ''].includes(userCreatedRole) && [undefined, null, ''].includes(preferredJobTitle)){
        toast.warning('Please Select Job Title')
        return
    }
    if([null, undefined, ''].includes(_jobId)){
        createPosterJob()
    }else{
        updatePosterJobTitle()
    }
}

function updatePosterJobTitle(){
    let jobTitle = ''
    if(userCreatedRole !== ''){
        jobTitle = userCreatedRole
    }else{
        jobTitle = preferredJobTitle
    }
    const obj = {'': {
        "_id": generateHexUUID(),
        "option": jobTitle,
        "category": "jobrole",
        "usertype": '',
        "priority": 1,
        "addedby": '',
        "userId": '',
        "status": 2,
        "orderBy": null
    }}
    updatePosterJobDataByKeyApi(_jobId, obj).then((_resp) => {
        
    }).catch((_resp) => {

    }).finally(() => {
        _onResp('next', null)
    })
}

function createPosterJob(){
    let jobTitle = ''
    if(userCreatedRole !== ''){
        jobTitle = userCreatedRole
    }else{
        jobTitle = preferredJobTitle
    }
    const obj = {
        "_id": generateHexUUID(),
        "option": jobTitle,
        "category": "jobrole",
        "usertype": '',
        "priority": 1,
        "addedby": '',
        "userId": '',
        "status": 2,
        "orderBy": null
    }
    const user_id = userData['_id']
    createPosterJobByJobTitleApi(user_id, _companyId, obj).then((_resp) => {
        const resp = _resp.data.Resp
        _onResp('set/job/id', resp['RecordId'])
        _onResp('next', null)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        // _onResp('next', null)
    })
}

return <>
<section id='job-role-steps'>
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className='discover-personality-top-cnt'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <img src={brand} alt='brand' className='w-80px' />
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-2' />
            <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                    <form id='msform'>
                        <fieldset>
                            <div className='form-card'>
                                <h3 className='job-que'>What job title best describes the role you are looking for?</h3>
                                <div className='role-wrapper red-radio-btn'>
                                    {jobListed.map((data, i) => (
                                        <div key={i + 1} className='role-col'>
                                            <div key={i + 2} className='inputGroup'>
                                                <input key={i + 3} id={data?._id} readOnly  checked={roleSelected.includes(data._id)}  name='radio'  type='radio' />
                                                <label key={i + 4} className='text-capitalize' htmlFor={data?._id}
                                                    onClick={() => { setRoleSelected([data._id])
                                                        setjobTitleForTextField('');
                                                        setUserCreatedRole(data.option);
                                                        setPreferredJobTitle('')
                                                    }}>
                                                    {data.option}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="mt-4 col-6">
                                    <span>Don't See your Preferred Job Title?</span>
                                    <input onChange={(e) => {
                                        setPreferredJobTitle(e.target.value)
                                        setUserCreatedRole('')
                                        setRoleSelected('')
                                        }} value={preferredJobTitle} type="text" className="form-control" placeholder="Custom Job Title..." />
                                </div>
                            </div>
                            <input onClick={() => {onProceedClick()}} type='button'  name='next' className='next action-button'  value=''/>
                        </fieldset>
                    </form>
                </div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 1/7
                            </h2>
                        </div>
                    </div>
                    <div className='progress'>
                        <div className='progress-bar' role='progressbar' style={{ width: '14%' }}  aria-valuenow='14' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className='col-lg-2' />
        </div>
    </div>
</section>
</>
}

export default JobTitleComp