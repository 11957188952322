import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import JobTitle from "./steps/job-title";
import EducationLevel from "./steps/education-level";
import SkillLevel from "./steps/skill-level";
import SalaryComp from "./steps/salary";
import HardSkillsStep from "./steps/hard-skills-step";
import SoftSkillsStep from "./steps/soft-skills-step";
import SetDateAndDescriptionComp from "./steps/set-date-description";

const PosterCreateJobWizard = () => {
const { company_id } = useParams();
const navigate = useNavigate();

// const stepIndex = parseInt(step) - 1;
const defaultStep = 0
const [currentStep, setCurrentStep] = useState(defaultStep);
const [jobId, setJobId] = useState(null)

const steps = [
    JobTitle, // 1
    SetDateAndDescriptionComp, // 16
    EducationLevel, // 4
    SkillLevel, // 5
    SalaryComp, // 8
    // SelfAssessment, // 9
    HardSkillsStep, // 14
    SoftSkillsStep, // 15
];

const StepComponent = steps[currentStep];

function onStepCompResp(_cmd, _value) {
    if(['next', 'back'].includes(_cmd)){
        setCurrentStep((prev) => {
            let nextStep = prev;
            if (_cmd === "next" && prev < steps.length - 1) {
                nextStep = prev + 1;
            } else if (_cmd === "back" && prev > 0) {
                nextStep = prev - 1;
            }else{
                navigate('/')
            }
            return nextStep;
        });
    }else if(_cmd === 'set/job/id'){
        setJobId(_value)
    }else if(_cmd === 'finish'){
        navigate(`/poster/view-job/${jobId}`)
    }
}
return (<>
    <div className="wizard-container">
        {StepComponent && <StepComponent _companyId={company_id} _jobId={jobId} _onResp={onStepCompResp} />}
    </div>
</>)
}

export default PosterCreateJobWizard